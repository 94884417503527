import "./App.css";
import Main from "./Component/Main";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./Component/Contact";
import Privacy from "./Component/Privacy";
import Particular from "./Component/Particular";
import Collect from "./Component/Collect";
import Recruit from "./Component/Recruit";
import Interview from "./Component/Interview";
import Student from "./Component/Student";
import Ob from "./Component/Ob";
import Teacher from "./Component/Teacher";
import Company from "./Component/Company";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/particular" element={<Particular />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/collect" element={<Collect />} />
        <Route path="/recruit" element={<Recruit />} />
        <Route path="/interview" element={<Interview />} />
        <Route path="/student" element={<Student />} />
        <Route path="/ob" element={<Ob />} />
        <Route path="/teacher" element={<Teacher />} />
        <Route path="/company" element={<Company />} />
      </Routes>
    </Router>
  );
}

export default App;
