import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/know.css";
import { Link } from "react-router-dom";

function Teacher() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="space_contact_box">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" onClick={toTop}>
              トップ
            </Link>
          </li>
          <li className="breadcrumbs__item">講師インタビュー</li>
        </ul>

        <div className="main_space_div">
          <div className="main_space_div_inner">
            <h1 className="heading-has-under-line">講師インタビュー</h1>
            <div className="course-introduction -course-A">
              <h2 className="course-introduction__lede">
                当校講師からのメッセージです
              </h2>
            </div>
            <div
              className="button-arrow-group -course-A"
              style={{ marginTop: "-10px" }}
            >
              <a href="/teacher" className="button-arrow -is-full">
                講師紹介はこちら
              </a>
            </div>
            <h3 className="heading-has-circle -course-A">
              福田健策／一級建築士・インテリアコーディネーター
            </h3>
            <p className="course-introduction__text">
              ”大事なのは「やりたい」という気持ち、 柔軟で自由な発想です”
            </p>
            <div className="course-detail">
              <figure className="course-detail__image">
                <img
                  src="https://www.space-design.co.jp/common/images/pages/interview/te-01-fukuda.jpg"
                  alt="福田健策"
                />
              </figure>
              <div className="course-detail__text-group">
                <p className="text-basic00">
                  スペースデザインカレッジの創設者でありながら、今もなお一級建築士・デザイナーとして活躍する福田健策。建築、インテリア業界に身を置きながら、延べ1800名以上の学生を見届けてきた彼だから語れる、これからの時代に求められるデザイナーとは。
                </p>
              </div>
            </div>

            <div className="course-detail__text-group">
              <p className="text-basic00">
                --------------
                <br />
                日本では「建築の方が上でインテリアはそのお手伝いをする」と考えられていることが多いのですが、僕は対等な立ち位置だと思っているんです。建築物ができてからインテリアを考えるのではなく、インテリアデザイナーの考える空間を建築することがあってもいいと思うし、建築士とデザイナーがやり取りしながら一つの建物を作っていくのが理想だと思います。だからインテリアデザイナーは自信をもって建築士に提案できるぐらいの建築知識を併せ持っていることが一番いい。まさにこれがスペースデザインカレッジを設立したときに目指したインテリアデザイナー像なんです。
                <br />
                <br />
              </p>
              <p className="course-introduction__text">
                ”数学や物理よりも大切なこと”
                <br />
              </p>
              <p className="text-basic00">
                この学校に入る人は建築に興味を持つような体験をしている人が多いんですよ。例えば家をリフォームしたり兄弟と一緒の部屋で何とか自分の空間が欲しくて模様替えをしたり。不動産屋のチラシの間取りを見ながらプランを想像している人もいるかもしれません。そのような「やりたい」という気持ちが僕は大切だと思っています。ところが憧れがあっても、数学や物理が苦手だったから、といった理由で諦めていた人が多いんです。僕自身も勉強が嫌いで数学も大嫌いでした。だけど実際の現場に出たら、そんなことは全然問題じゃなかったんです。だから趣味でもいいから勉強してごらんよ、おもしろいから、と。最初はそこからでもいいんです。
                <br />
                <br />
              </p>
              <p></p>
            </div>

            <div className="course-detail__text-group">
              <p className="course-introduction__text">
                ”建築とインテリアを横断する”
                <br />
              </p>
              <p className="text-basic00">
                問題は理想的なインテリアデザイナー・・・つまり建築士と共に空間を創り上げられるデザイナーになるためには、建築の「理論」をわかった上で「感覚」の話をしなければならないということなんです。ところが今、日本の教育現場で建築とインテリアが別々に教えられている。実際の現場は建築とインテリアが分かれていない。だから、この学校ではインテリアをやりたい人でも建築を学べるようにしています。
                <br />
                <br />
              </p>
              <p></p>
            </div>

            <div className="course-detail__text-group">
              <p className="course-introduction__text">
                ”オールラウンドが近道になる”
                <br />
                <br />
                学生たちには専門特化できる分野を持っておけよと言いますが、でも基本的にはオールラウンドであるべきだと考えます。実はそれが、これから活躍できるデザイナーになる近道だからです。既成概念や固定概念に捉われずに、柔軟で自由な発想が大切です。自分の好きを仕事にするために、思い切って飛び込んでください。
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Teacher;
