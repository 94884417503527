import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import { Link } from "react-router-dom";

function Main() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <div className="mainBox">
        <Header />
        <div className="g-inner">
          <div className="first_top_box">
            <h1 className="top-sentence">
              インテリアコーディネーター・空間デザイン専門の学校／スペースデザインカレッジ
            </h1>
            <img
              height="859"
              width="100%"
              alt=""
              src="	https://www.space-design.co.jp/common/images/components/top-keyvisual-v-02@2x.jpg"
            />
            <Link to="/news" className="top-keyvisual__button__v">
              <span className="top-keyvisual__button-text__v">
                入学願書 受付中
              </span>
            </Link>
          </div>
          <ul className="top-campuses_3">
            <li className="top-campuses__item">
              <Link to="/">
                <img
                  src="https://www.space-design.co.jp/common/images/components/top-campuses-5-k@2x.jpg"
                  alt="スペースデザインカレッジ 京都校"
                />
              </Link>
            </li>
            <li className="top-campuses__item">
              <Link to="/">
                <img
                  src="	https://www.space-design.co.jp/common/images/components/top-campuses-3-o@2x.jpg"
                  alt="スペースデザインカレッジ 大阪校"
                />
              </Link>
            </li>
            <li className="top-campuses__item">
              <Link to="/">
                <img
                  src="	https://www.space-design.co.jp/common/images/components/top-campuses-3-t@2x.jpg"
                  alt="スペースデザインカレッジ 東京校"
                />
              </Link>
            </li>
            <li className="top-campuses__item">
              <Link to="/">
                <img
                  src="	https://www.space-design.co.jp/common/images/components/top-campuses-5-y-2@2x.jpg"
                  alt="スペースデザインカレッジ 横浜校（神奈川）"
                />
              </Link>
            </li>
          </ul>
          <div className="p-top-heading-group-first">
            <h2 className="heading-has-canter-line-h2">SDCの実績</h2>
            <div className="image-basic-is-full">
              <img
                alt=""
                src="	https://www.space-design.co.jp/common/images/components/abouts_2024_04.jpg"
              />
            </div>
          </div>

          <div className="top-banner-new-sec">
            <div className="image-basic_2 -is-full">
              <img
                src="	https://www.space-design.co.jp/common/images/slide/banner_ippan_241031.jpg"
                alt="2025年春入学生 一般入学願書受付中"
              />
            </div>
            <ul className="new-banners">
              <li>
                <img
                  src="https://www.space-design.co.jp/common/images/slide/banner--lw.jpg"
                  alt="出張説明会"
                />
              </li>
              <li>
                <img
                  src="https://www.space-design.co.jp/common/images/slide/oc-0000.jpg"
                  alt="体験授業（オープンカレッジ）ご予約受付中"
                />
              </li>
            </ul>
          </div>

          <div className="p-top-heading-group-third">
            <h2
              className="heading-has-canter-line-h2"
              style={{ marginBottom: "0" }}
            >
              SDCの魅力
            </h2>
            <p className="text-basic-is-center">
              20歳から学ぶインテリアコーディネーター専門の学校
              <br />
              スペースデザインカレッジの魅力
            </p>
          </div>

          <div className="top-four-points">
            <ol className="course-point-center -course-C">
              <li className="course-point-center__item">
                <div className="course-point-center__summary">
                  入学資格が20歳以上。
                </div>
                <p className="text-basic">
                  それは、将来を真剣に考え、「ブレることのない意志」を持っていれば、本気で学び本気で仕事をしていくだろうと考えるから。
                  <br />
                  その線引きが20歳。
                  <br />
                  スペースデザインカレッジでは、20代から50代まで、幅広い年齢層の方が学んでいます。
                  <br />
                  その点が他のインテリア専門学校との違いです。
                </p>
              </li>

              <li className="course-point-center__item">
                <div className="course-point-center__summary">
                  講師はプロのインテリアコーディネーターやデザイナー、建築家。
                </div>
                <p className="text-basic">
                  成功するためには「成功者」に聞くべきです。
                  <br />
                  スペースデザインカレッジの講師陣は、現役で活躍するプロのインテリアコーディネーターや建築家。
                  <br />
                  授業の大半を実践的な課題に割くことで、社会が求めるデザインのニーズや就職後に必要とされるスキルをプロによる指導で習得することができます。
                </p>
              </li>

              <li className="course-point-center__item">
                <div className="course-point-center__summary">
                  夢の実現まで
                  <br className="pc-only-br" />
                  キャリアプランナーがサポート。
                </div>
                <p className="text-basic">
                  定期的に学生と進路について個別面談を行います。
                  <br />
                  進路相談、就職先の紹介、学生生活などについて、一人一人に対して細やかなサポートをしています。
                </p>
              </li>

              <li className="course-point-center__item">
                <div className="course-point-center__summary">
                  27年連続、
                  <br className="pc-only-br" />
                  インテリアコンテスト受賞。
                </div>
                <p className="text-basic">
                  インテリアコーディネーター資格試験を主宰しているインテリア産業協会（後援/経済産業省）が毎年、プロと学生を対象に「住まいのインテリアコーディネーションコンテスト」と「キッチン空間アイデアコンテスト」を開催しています。
                  <br />
                  スペースデザインカレッジでは開校以来、27年連続で各賞を受賞しています（2024年現在）。
                  <br />
                  歴代の受賞者数は160名を超え、日本最多です。
                  <br />
                  教える方も、教わる方も真剣だからこそ、結果につながっています。
                </p>
              </li>

              <li className="course-point-center__item">
                <div className="course-point-center__summary">
                  教育ローンで
                  <br className="pc-only-br" />
                  月々4200円から学べる。
                </div>
                <p className="text-basic">
                  学費をローンでお支払いされている方も少なくありません。
                  <br />
                  なるべく負担なく学べるように、皆さんに適したお支払い方法をご用意しています。
                  <br />
                  国の教育ローン/各銀行の教育ローン/一括支払い…等々無理のない計画でしっかり勉強に打ちこめる環境づくりも学校のサポートと考え、専門のスタッフがきちんと対応させていただきます。
                </p>
              </li>
            </ol>
          </div>

          <div className="topics_div_five">
            <img
              alt=""
              src="https://www.space-design.co.jp/common/images/pages/topics/topics_241004.jpg"
            />
          </div>

          <div className="courses-0">
            <ul className="courses-0__list">
              <li
                style={{
                  backgroundImage: `url("https://www.space-design.co.jp/common/images/components/courses-kd.jpg")`,
                }}
              >
                <div className="courses-0__text">
                  インテリアと建築の
                  <br />
                  空間デザインを学ぶコース
                </div>
                <div className="courses-0__name">
                  空間デザイン科 選択制
                  <br />
                  （１年制・２年制）
                </div>
              </li>
              <li
                style={{
                  backgroundImage: `url("	https://www.space-design.co.jp/common/images/components/courses-1001@2x.jpg")`,
                }}
              >
                <div className="courses-0__text">
                  週１回
                  <br />
                  インテリアコーディネートを
                  <br />
                  学ぶコース
                </div>
                <div className="courses-0__name">
                  インテリア
                  <br />
                  コーディネーター科
                  <br />
                  （週１日・１年制）
                </div>
              </li>
              <li
                style={{
                  backgroundImage: `url("	https://www.space-design.co.jp/common/images/components/courses-19@2x.jpg")`,
                }}
              >
                <div className="courses-0__text">
                  自宅にいながら
                  <br />
                  コーディネーターの知識と技術を
                  <br />
                  身につけるコース
                </div>
                <div className="courses-0__name">
                  インテリア
                  <br />
                  コーディネーター科
                  <br />
                  （オンラインLiveコース１年制）
                </div>
              </li>
              <li
                style={{
                  backgroundImage: `url("https://www.space-design.co.jp/common/images/components/courses-20@2x.jpg")`,
                }}
              >
                <div className="courses-0__text">
                  インテリアコーディネーター資格
                  <br />
                  二次試験
                  <br />
                  合格のための対策講座
                </div>
                <div className="courses-0__name">
                  インテリア
                  <br />
                  コーディネーター資格
                  <br />
                  二次試験対策コース
                </div>
              </li>
            </ul>
          </div>

          <div className="knowing-business" style={{ padding: "80px 28px 0" }}>
            <div className="knowing-sdc">
              <div className="knowing-sdc__inner">
                <h2 className="heading-knowledge">
                  <span className="heading-knowledge__en">Knowing SDC</span>
                  スペースデザインカレッジを知る
                </h2>
                <ul className="knowing-sdc__list">
                  <li className="knowing-sdc__item">
                    <Link to="/recruit" onClick={toTop}>
                      <img
                        src="https://www.space-design.co.jp/common/images/components/knowing-SDC-01@2x.jpg"
                        alt="SDCを知る"
                      />
                      <span className="knowing-sdc__text">学生就職状況</span>
                    </Link>
                  </li>
                  <li className="knowing-sdc__item">
                    <Link to="/interview" onClick={toTop}>
                      <img
                        src="	https://www.space-design.co.jp/common/images/components/knowing-SDC-02@2x.jpg"
                        alt="27年連続でコンテスト受賞"
                      />
                      <span className="knowing-sdc__text">
                        27年連続で
                        <br />
                        コンテスト受賞
                      </span>
                    </Link>
                  </li>
                  <li className="knowing-sdc__item">
                    <Link to="/student" onClick={toTop}>
                      <img
                        src="https://www.space-design.co.jp/common/images/components/knowing-SDC-04@2x.jpg"
                        alt="在校生インタビュー"
                      />
                      <span className="knowing-sdc__text">
                        在校生
                        <br />
                        インタビュー
                      </span>
                    </Link>
                  </li>
                  <li className="knowing-sdc__item">
                    <Link to="/ob" onClick={toTop}>
                      <img
                        src="	https://www.space-design.co.jp/common/images/components/knowing-SDC-05@2x.jpg"
                        alt="卒業生インタビュー"
                      />
                      <span className="knowing-sdc__text">
                        卒業生
                        <br />
                        インタビュー
                      </span>
                    </Link>
                  </li>
                  <li className="knowing-sdc__item">
                    <Link to="/teacher" onClick={toTop}>
                      <img
                        src="	https://www.space-design.co.jp/common/images/components/knowing-SDC-06@2x.jpg"
                        alt="講師インタビュー"
                      />
                      <span className="knowing-sdc__text">
                        講師
                        <br />
                        インタビュー
                      </span>
                    </Link>
                  </li>
                  <li className="knowing-sdc__item">
                    <Link to="/company" onClick={toTop}>
                      <img
                        src="https://www.space-design.co.jp/common/images/components/knowing-SDC-07@2x.jpg"
                        alt="企業から見たSDC"
                      />
                      <span className="knowing-sdc__text">企業から見たSDC</span>
                    </Link>
                  </li>
                  <li className="knowing-sdc__item">
                    <Link to="/" onClick={toTop}>
                      <img
                        src="https://www.space-design.co.jp/common/images/components/knowing-SDC-08@2x.jpg"
                        alt="学生の作品"
                      />
                      <span className="knowing-sdc__text">
                        学生の作品
                        <br />
                        (インスタグラム)
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="look-for-jobs">
            <div className="look-for-jobs__inner">
              <h2 className="heading-knowledge">
                <span className="heading-knowledge__en">Look for Search</span>
                なりたい職業を探す
              </h2>
              <ul className="look-for-jobs__list">
                <li className="look-for-jobs__item">
                  インテリアコーディネーター
                </li>
                <li className="look-for-jobs__item">インテリアデザイナー</li>
                <li className="look-for-jobs__item">店舗デザイナー</li>
                <li className="look-for-jobs__item">建築家</li>
                <li className="look-for-jobs__item">ディスプレイデザイナー</li>
                <li className="look-for-jobs__item">インテリアスタイリスト</li>
                <li className="look-for-jobs__item">インテリアアドバイザー</li>
                <li className="look-for-jobs__item">照明デザイナー</li>
                <li className="look-for-jobs__item">家具デザイナー</li>
                <li className="look-for-jobs__item">インテリアプランナー</li>
              </ul>
            </div>
          </div>

          <div className="knowing-business" style={{ padding: "80px 28px" }}>
            <div className="knowing-business__inner">
              <h2 className="heading-knowledge">
                <span className="heading-knowledge__en">Knowledge</span>
                業界を知る
              </h2>
              <ul className="knowing-business__list">
                <li className="knowing-business__item">
                  <img
                    src="	https://www.space-design.co.jp/common/images/components/knowing-business-01@2x.png"
                    alt=""
                  />
                  デザインは
                  <br />
                  才能が必要？
                </li>
                <li className="knowing-business__item">
                  <img
                    src="	https://www.space-design.co.jp/common/images/components/knowing-business-02@2x.png"
                    alt=""
                  />
                  インテリア、
                  <br />
                  建設業界について
                </li>
                <li className="knowing-business__item">
                  <img
                    src="	https://www.space-design.co.jp/common/images/components/knowing-business-03@2x.png"
                    alt=""
                  />
                  インテリアと建築の
                  <br />
                  仕事ってちがうの？
                </li>
                <li className="knowing-business__item">
                  <img
                    src="	https://www.space-design.co.jp/common/images/components/knowing-business-04@2x.png"
                    alt=""
                    width="196"
                    height="151"
                  />
                  職種、肩書き、
                  <br />
                  それぞれの違い。
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
