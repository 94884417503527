import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/know.css";
import { Link } from "react-router-dom";

function Student() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="space_contact_box">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" onClick={toTop}>
              トップ
            </Link>
          </li>
          <li className="breadcrumbs__item">在校生インタビュー</li>
        </ul>

        <div className="main_space_div">
          <div className="main_space_div_inner">
            <h1 className="heading-has-under-line">在校生インタビュー</h1>
            <div className="course-introduction -course-B">
              <h2 className="course-introduction__lede">
                インテリア・建築業界を目指している在校生をご紹介します
              </h2>
            </div>
            <h3 className="heading-has-circle -course-B">
              インテリアコーディネーター科／作山結香さん{" "}
            </h3>
            <div className="course-detail">
              <figure className="course-detail__image">
                <img
                  src="https://www.space-design.co.jp/common/images/pages/interview/st-sakuyama.jpg"
                  alt="作山結香さん"
                />
              </figure>
              <div className="course-detail__text-group">
                <p className="text-basic00">
                  元々は営業の仕事をされていた作山さん。在学中に転職活動を行い、コーディネーターとしての内定が決まった彼女へお話を伺いました。
                </p>
              </div>
            </div>

            <div className="course-detail__text-group">
              <p className="text-basic00">
                --------------
                <br />
                <span className="heading-basic -course-B">
                  入学のきっかけは何ですか？
                </span>
                <br />
                <br />
                前職は住宅メーカーで営業をしていましたが、在職中からインテリアが好きでインテリアコーディネーター職に興味を持っていました。しかし、コーディネーターとしての知識も経験もなかったため、インテリアの勉強をし転職への一つのステップになればと思い入学しました。
                <br />
                <br />
                <span className="heading-basic -course-B">
                  SDCで学び、どんなものを得られましたか？
                </span>
                <br />
                <br />
                インテリアや建築の知識はもちろんですが、プラン作成やプレゼンテーションなど実務に近い経験をさせていただいていることが今後の自信にも繋がると思っています。
                <br />
                <br />
                <span className="heading-basic -course-B">
                  就職活動はどうでしたか？
                </span>
                <br />
                <br />
                新卒時の就活以来の久々の面接、また転職活動は初めてで不安もありましたので、建築業界に強いエージェントに相談しながら進めていきました。
                <br />
                私自身、実務経験がないことが一つの懸念点ではありましたが、面接時に学校で勉強している内容や、実際に作成した課題を提示したことで、全くの未経験者との差をつけることができ内定に繋がったのではないかと思います。
                <br />
                <br />
                <span className="heading-basic -course-B">
                  内定したお仕事の内容は？
                </span>
                <br />
                <br />
                個人住宅の床壁天井などの内部仕上げ材や住宅設備の選定。また、建売住宅に関しては家具付きで販売するため、インテリアのコーディネーションも担当します。
                <br />
                <br />
                <span className="heading-basic -course-B">
                  これから勉強される方へメッセージをお願いします。
                </span>
                <br />
                <br />
                SDCの魅力は、その道のプロの先生から教わることができる点です。授業や課題を通じて先生方から直接アドバイスをいただき、知識を積み重ねていくことで着実に力がついていくと思いますので、ぜひ授業を楽しみながら学んでいってください。
                <br />
                <br />
              </p>
            </div>

            <h3 className="heading-has-circle -course-B">
              インテリアコーディネーター科／潮田薫さん{" "}
            </h3>

            <div className="course-detail">
              <figure className="course-detail__image">
                <img
                  src="https://www.space-design.co.jp/common/images/pages/interview/st-01-ushioda.jpg"
                  alt="潮田薫さん"
                />
              </figure>
              <div className="course-detail__text-group">
                <p className="text-basic00">
                  建築の塗装会社で事務の仕事をしている潮田さん。塗装の色などの相談を受けることもあり、コーディネートにも興味が湧いていました。
                  <br />
                  そんな時、会社の新事業としてインテリア全般のデザインを請け負う方向に。自身の仕事の幅を広げるチャンスだと考え、働きながら勉強することを決意されました。
                </p>
              </div>
            </div>

            <div className="course-detail__text-group">
              <p className="text-basic00">
                --------------
                <br />
                知識や技術が増えていくことが仕事につながると思うと楽しいですね。その中でも自分がデザインしたインテリア空間を絵で表現することが好きです。同じ「描く事」ですが、図面は好きになれません。でも必要な技術なので、心を無にして描いてます（笑）。
                <br />
                仕事との両立はうまく出来ている感じです。入学前は不安でしたが、やってみると何とかなるもので。忙しいときもありますが、家事については夫にも協力してもらっていて、感謝しています。
                <br />
                クラスメートも働きながら通っているので、両立の苦労もあるようです。
                <br />
                でも、大変なのは自分だけではないと思うと、頑張ろうという気持ちが湧いてきます。大人になってから勉強する機会があるのは、とても恵まれていることだと思います。そう考えるとチャレンジすべきではないでしょうか。
                <br />
                できるかできないかは考えてもわからないので、やってみて判断する。後悔しないように前向きに考えてみてはいかがですか。
                <br />
                <br />
              </p>
            </div>

            <h3 className="heading-has-circle -course-B">
              インテリアデザイン設計科／山口七海さん
            </h3>

            <div className="course-detail">
              <figure className="course-detail__image">
                <img
                  src="	https://www.space-design.co.jp/common/images/pages/interview/st-02-yamaguchi.jpg"
                  alt="山口七海さん"
                />
              </figure>
              <div className="course-detail__text-group">
                <p className="text-basic00">
                  <span className="redtext">
                    ※インテリアデザイン設計科...現・空間デザイン科インテリアデザイン専攻
                  </span>
                  <br />
                  <br />
                  入学前は長野県の歯科医で5年間歯科助手として勤務していた山口さん。高齢者が来院し、院内の移動や立ち座りの様子を日々見る中、もっと人にやさしい空間は出来ないものかと考えるようになったのが入学のきっかけ。一念発起し東京で一人暮らしを始め、インテリアデザイナーを目指し勉強とアルバイトを両立する日々を送っている。
                </p>
              </div>
            </div>

            <div className="course-detail__text-group">
              <p className="text-basic">
                --------------
                <br />
                皆と一緒に勉強できることが楽しいですね。
                <br />
                一人で集中して勉強することも必要ですが、皆の作品制作の過程を見ることができるので、刺激になります。授業中、友達が質問する発言も自分では気付かなかったことを気付かせてくれます。とても勉強になりますね。
                <br />
                好きな授業はパースや着彩など絵を描く授業です。学生時代は美術部で、元々絵が好きだったんですが、今まで知らなかった新しい表現技法を身に付けることができて幅が広がりました。床材、壁材、窓飾り、家具など、数多くの商品からコーディネートする作業も面白いです。
                <br />
                デザインの歴史やインテリアの商品知識など、知らなかったことを知る楽しさもあります。この楽しさは２０代になってわかることなのかもしれません。
                <br />
                ただ、楽しいことばかりではないです。
                <br />
                例えば住宅のインテリアデザイン課題で「こうしたい」という思いはあるのですが、それを具体的なカタチにすることが簡単にできません。とてももどかしいです。作業の効率も悪く、時間ばかり経ってしまって。
                <br />
                そんな時は色々な先生に相談するのが良いです。一人で悩むより、「こんな空間にしたいんだ！」ということを言葉で説明すると、先生方が良いヒントを与えてくれます。
                <br />
                遠慮せず積極的にぶつかることですね。
              </p>
            </div>
            <h3 className="heading-has-circle -course-B">
              スペースデザイン設計科／藤井みのりさん
            </h3>
            <div className="course-detail">
              <figure className="course-detail__image">
                <img
                  src="	https://www.space-design.co.jp/common/images/pages/interview/st-03-fujii.jpg"
                  alt="藤井みのりさん"
                />
              </figure>
              <div className="course-detail__text-group">
                <p className="text-basic">
                  <span className="redtext">
                    ※スペースデザイン設計科...現・空間デザイン科スペースデザイン専攻
                  </span>
                  <br />
                  <br />
                  店舗企画＆デザインを中心にオフィス、住宅、家具デザイン等幅広く手がけているデザイン事務所に決まりました。
                  <br />
                  スペースデザインカレッジの卒業生と交流があって、昨年スノーボードへ...。
                  <br />
                  その時に就職や人生について色々と相談にのってもらったのですが、私の希望している仕事内容に近い求人を知っているということで、紹介してもらったのがきっかけです。
                  <br />
                  実は紹介してもらったデザイン事務所にはスペースデザインカレッジの卒業生が働いていまして、卒業生のネットワークって素晴らしいな、と感じているところです。
                  <br />
                  ありきたりですが、早く仕事を憶えて信頼される人材になりたいです。
                  <br />
                  自分でデザインした商業空間が現実に出来上がる日が一日でも早くなるように努力していきたいですね。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Student;
