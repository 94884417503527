import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileLines,
  faLocationDot,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="footer-info">
          <h2 className="heading-info">Infomation</h2>
          <ul className="footer-info__list">
            <li className="footer-info__item">
              <Link to="/collect" onClick={toTop}>
                <figure className="footer-info__icon">
                  <FontAwesomeIcon
                    icon={faFileLines}
                    style={{ color: "#2e96e6" }}
                  />
                </figure>
                <div className="footer-info__text">
                  学費詳細・募集要項
                  <span className="footer-info__en">
                    Tuition &amp; Requirements
                  </span>
                </div>
              </Link>
            </li>
            <li className="footer-info__item">
              <Link to="/particular" onClick={toTop}>
                <figure className="footer-info__icon">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ color: "#2e96e9" }}
                  />
                </figure>
                <div className="footer-info__text">
                  アクセス情報
                  <span className="footer-info__en">Access</span>
                </div>
              </Link>
            </li>
            <li className="footer-info__item">
              <Link to="/" onClick={toTop}>
                <figure className="footer-info__icon">
                  <FontAwesomeIcon
                    icon={faCircleQuestion}
                    style={{ color: "#2e96e6" }}
                  />
                </figure>
                <div className="footer-info__text">
                  よくあるご質問
                  <span className="footer-info__en">FAQ</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-page-top">
          <Link to="#">ページトップ</Link>
        </div>

        <div className="footer-pages">
          <div className="footer-pages__colmun">
            <div className="footer-pages__category">
              <div
                className="footer-pages__label js-toggle-slide"
                aria-expanded="false"
              >
                SDCについて
              </div>
              <ul className="footer-pages__list" aria-hidden="true">
                <li className="footer-pages__item">学校方針</li>
                <li className="footer-pages__item">SDCの魅力</li>
                <li className="footer-pages__item">講師紹介</li>
              </ul>
            </div>
            <div className="footer-pages__category">
              <div
                className="footer-pages__label js-toggle-slide"
                aria-expanded="false"
              >
                コース案内
              </div>
              <ul className="footer-pages__list" aria-hidden="true">
                <li className="footer-pages__item">コース一覧</li>
                <li className="footer-pages__item">
                  空間デザイン科スペースデザイン専攻
                </li>
                <li className="footer-pages__item">
                  空間デザイン科インテリアデザイン専攻
                </li>
                <li className="footer-pages__item">
                  インテリアコーディネーター科
                </li>
                <li className="footer-pages__item">
                  インテリアコーディネーター科オンライン（通信講座）
                </li>
                <li className="footer-pages__item">
                  インテリアコーディネーター資格二次対策オンライン（通信講座）
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-pages__colmun">
            <div className="footer-pages__category">
              <div
                className="footer-pages__label js-toggle-slide"
                aria-expanded="false"
              >
                校舎一覧
              </div>
              <ul className="footer-pages__list" aria-hidden="true">
                <li className="footer-pages__item">東京</li>
                <li className="footer-pages__item">大阪</li>
                <li className="footer-pages__item">京都</li>
                <li className="footer-pages__item">横浜（神奈川）</li>
              </ul>
            </div>
            <div className="footer-pages__category">
              <div
                className="footer-pages__label js-toggle-slide"
                aria-expanded="false"
              >
                入学案内
              </div>
              <ul className="footer-pages__list" aria-hidden="true">
                <li className="footer-pages__item">募集要項</li>
                <li className="footer-pages__item">
                  学費のお支払い方法について
                </li>
                <li className="footer-pages__item">資料請求</li>
                <li className="footer-pages__item">学校説明会</li>
                <li className="footer-pages__item">出張説明会（遠方の方へ）</li>
                <li className="footer-pages__item">体験授業</li>
                <li className="footer-pages__item">Web出願</li>
                <li className="footer-pages__item">社会人の方へ</li>
                <li className="footer-pages__item">大学生の方へ</li>
                <li className="footer-pages__item">外国籍の方へ</li>
                <li className="footer-pages__item">未成年の方へ</li>
              </ul>
            </div>
          </div>
          <div className="footer-pages__colmun">
            <div className="footer-pages__category">
              <div
                className="footer-pages__label js-toggle-slide"
                aria-expanded="false"
              >
                就職サポート
              </div>
              <ul className="footer-pages__list" aria-hidden="true">
                <li className="footer-pages__item">学生就職状況</li>
                <li className="footer-pages__item">就職内定者の声</li>
              </ul>
            </div>
            <div className="footer-pages__category">
              <div
                className="footer-pages__label js-toggle-slide"
                aria-expanded="false"
              >
                インタビュー
              </div>
              <ul className="footer-pages__list" aria-hidden="true">
                <li className="footer-pages__item">在学生インタビュー</li>
                <li className="footer-pages__item">卒業生インタビュー</li>
                <li className="footer-pages__item">講師インタビュー</li>
                <li className="footer-pages__item">企業さまインタビュー</li>
                <li className="footer-pages__item">
                  コンテスト入賞者インタビュー
                </li>
              </ul>
            </div>
            <div className="footer-pages__category">
              <div
                className="footer-pages__label js-toggle-slide"
                aria-expanded="false"
              >
                もっと知るSDC
              </div>
              <ul className="footer-pages__list" aria-hidden="true">
                <li className="footer-pages__item">なりたい職業について</li>
                <li className="footer-pages__item">インテリア業界の資格</li>
                <li className="footer-pages__item">インテリアの世界を学ぶ</li>
              </ul>
            </div>
          </div>
          <div className="footer-pages__colmun">
            <div className="footer-pages__category">
              <div
                className="footer-pages__label js-toggle-slide"
                aria-expanded="false"
              >
                インフォメーション
              </div>
              <ul className="footer-pages__list" aria-hidden="true">
                <li className="footer-pages__item">
                  <Link to="/contact" onClick={toTop}>
                    お問い合わせ
                  </Link>
                </li>
                <li className="footer-pages__item">
                  <Link to="/particular" onClick={toTop}>
                    アクセス情報
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-pages__category">
              <div className="footer-pages__label -has-link">
                <Link to="/privacy" onClick={toTop}>
                  プライバシーポリシー
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-buttons">
          <Link to="/" className="footer-buttons__item -explanation">
            学校説明
          </Link>
          <Link to="/" className="footer-buttons__item -material">
            資料請求
          </Link>
        </div>

        <div className="l-footer__inner">
          <div className="l-footer__logo">
            <Link to="/" onClick={toTop}>
              <img alt="" src="/head/title.png" />
            </Link>
          </div>
          <div className="l-footer__copyright">
            Copyright ©
            <Link to="/">
              インテリアコーディネーター・空間デザイン専門の学校 SPACE DESIGN
              COLLEGE
            </Link>
            ALLRIGHTS RESERVED.
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
