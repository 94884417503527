import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import "../css/particular.css";

function Particular() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="space_contact_box">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" onClick={toTop}>
              トップ
            </Link>
          </li>
          <li className="breadcrumbs__item">アクセス</li>
        </ul>

        <div className="main_space_div">
          <div className="main_space_div_inner">
            <h1 className="heading-has-under-line">アクセス</h1>
            <h2 className="heading-has-circle">
              スペースデザインカレッジ所在地
            </h2>
            <h3 className="heading-has-bg">東京校</h3>

            <div className="particular_map_div">
              <img width="100%" alt="" src="/head/map1.png" />
            </div>
            <h4 className="heading-basic">所在地</h4>
            <p className="text-basic-particular">
              〒160-0022 東京都新宿区新宿2-9-22 多摩川新宿ビル8階
            </p>
            <h4 className="heading-basic">TEL</h4>
            <p className="text-basic-particular">
              <a href="tel:0359192700">(415) 425-9345</a>
            </p>
            <h4 className="heading-basic">MAIL</h4>
            <p className="text-basic-particular">work@huiixu.click</p>
            <h4 className="heading-basic">最寄駅</h4>
            <p className="text-basic-particular">
              <font _mstmutation="1">
                新宿御苑前駅　1番、3番出口より徒歩2分
                <br _mstmutation="1" />
                新宿三丁目駅　C4出口より徒歩7分
              </font>
              <br />
              <h4 className="heading-basic">ポイント</h4>
              <p className="text-basic-particular">
                新宿御苑前駅からすぐの場所にあり、各地域からのアクセスが集まっている好立地な学校です。
              </p>
              <h3 className="heading-has-bg">京都校</h3>
            </p>
            <div className="particular_map_div">
              <img width="100%" alt="" src="/head/map2.png" />
            </div>
            <h4 className="heading-basic">所在地</h4>
            <p className="text-basic-particular">
              〒604-8152 京都府京都市中京区手洗水町650 四条烏丸スタービル8F
            </p>
            <h4 className="heading-basic">TEL</h4>
            <p className="text-basic-particular">
              <a href="tel:0752531561">2136496328</a>
            </p>
            <h4 className="heading-basic">MAIL</h4>
            <p className="text-basic-particular">work@huiixu.click</p>
            <h4 className="heading-basic">最寄駅</h4>
            <p className="text-basic-particular">
              地下鉄烏丸線「四条駅」、阪急京都線「烏丸駅」
            </p>
            <h4 className="heading-basic">ポイント</h4>
            <p className="text-basic-particular">
              京都の中心地で駅から近く、アクセスの良い学校です。
            </p>
            <h3 className="heading-has-bg">大阪校</h3>
            <div className="particular_map_div">
              <img width="100%" alt="" src="/head/map3.png" />
            </div>
            <h4 className="heading-basic">住所</h4>
            <p className="text-basic-particular">
              〒530-0047 大阪府大阪市北区⻄天満2-8-1 大江ビルヂング202・203・218
            </p>
            <h4 className="heading-basic">TEL</h4>
            <p className="text-basic-particular">
              <a href="tel:0663650017">(415) 425-9345</a>
            </p>
            <h4 className="heading-basic">MAIL</h4>
            <p className="text-basic-particular">work@huiixu.click</p>
            <h4 className="heading-basic">最寄駅</h4>
            <p className="text-basic-particular">
              阪急「大阪梅田駅」、JR「大阪駅」、京阪本線・地下鉄御堂筋線「淀屋橋駅」、JR「北新地駅」
            </p>
            <h4 className="heading-basic">ポイント</h4>
            <p className="text-basic-particular">
              多くの駅からアクセス可能な、通いやすい学校です。
            </p>
            <h3 className="heading-has-bg">横浜校</h3>
            <div className="particular_map_div">
              <img width="100%" alt="" src="/head/map4.png" />
            </div>
            <h4 className="heading-basic">所在地</h4>
            <p className="text-basic-particular">
              〒231-0002 神奈川県横浜市中区海岸通4-23 マリンビル506
            </p>
            <h4 className="heading-basic">TEL</h4>
            <p className="text-basic-particular">
              <a href="tel:0452639318">2136496328</a>
            </p>
            <h4 className="heading-basic">MAIL</h4>
            <p className="text-basic-particular">work@huiixu.click</p>
            <h4 className="heading-basic">最寄駅</h4>
            <p className="text-basic-particular">
              みなとみらい線「馬車道駅」、JR根岸線「桜木町駅」「関内駅」
            </p>
            <h4 className="heading-basic">ポイント</h4>
            <p className="text-basic-particular">
              馬車道駅６番出口より徒歩3分。各地域からのアクセスが集まっている好立地な学校です。
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Particular;
