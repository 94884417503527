import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import "../css/contact.css";

function Contact() {
  const [formErrors, setFormErrors] = useState({});
  const [contactMsg, setContactMsg] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    nameReading: "",
    phoneNumber: "",
    mailAdd: "",
  });
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "お名前が入力されていません";
    if (!formData.nameReading)
      newErrors.nameReading = "ふりがなが入力されていません";
    if (!formData.phoneNumber)
      newErrors.phoneNumber = "電話番号が入力されていません";
    if (!formData.mailAdd)
      newErrors.mailAdd = "メールアドレスが入力されていません";

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (Object.keys(errors).length === 0) {
      alert("送信成功!");
      setFormData({
        name: "",
        nameReading: "",
        phoneNumber: "",
        mailAdd: "",
      });
      setContactMsg("");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="space_contact_box">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" onClick={toTop}>
              トップ
            </Link>
          </li>
          <li className="breadcrumbs__item">お問い合わせフォーム</li>
        </ul>

        <div className="main_space_div">
          <div className="main_space_div_inner">
            <h1 className="heading-has-under-line">お問い合わせフォーム</h1>
            <h2 className="heading-has-circle">
              当校へのお問い合わせはこちらのフォームからお願いいたします。
            </h2>
            <p className="text-basic11">
              以下、必要事項とお問い合わせ内容をご入力ください。
            </p>
            <p className="text-basic11 redtext">
              ※ビジネス・営業の内容はお断りいたします。
              <br />
              お問い合わせいただいても返答いたしかねますので、あらかじめご了承ください。
            </p>

            <form id="mail_form">
              <dl>
                <dt>
                  <span className="required">必須</span>お名前
                  <span>Your Name</span>
                </dt>
                <dd className="required">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <br />
                  {formErrors.name && (
                    <span className="contact_error">{formErrors.name}</span>
                  )}
                </dd>

                <dt>
                  <span className="required">必須</span>ふりがな
                  <span>Name Reading</span>
                </dt>
                <dd className="required">
                  <input
                    type="text"
                    id="nameReading"
                    name="nameReading"
                    value={formData.nameReading}
                    onChange={handleChange}
                  />
                  <br />
                  {formErrors.nameReading && (
                    <span className="contact_error">
                      {formErrors.nameReading}
                    </span>
                  )}
                </dd>
                <dt>
                  <span className="required">必須</span>電話番号
                  <span>Phone Number</span>
                </dt>
                <dd className="required">
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                  <br />
                  {formErrors.phoneNumber && (
                    <span className="contact_error">
                      {formErrors.phoneNumber}
                    </span>
                  )}
                </dd>
                <dt>
                  <span className="required">必須</span>メールアドレス
                  <span>Mail Address </span>
                </dt>
                <dd className="required">
                  <input
                    type="email"
                    id="mailAdd"
                    name="mailAdd"
                    value={formData.mailAdd}
                    onChange={handleChange}
                  />
                  <br />
                  {formErrors.mailAdd && (
                    <span className="contact_error">{formErrors.mailAdd}</span>
                  )}
                </dd>
                <dt>
                  <span className="optional">任意</span>お問い合わせ内容
                  <span>Contact</span>
                </dt>
                <dd>
                  <textarea
                    id="contents"
                    name="contents"
                    cols="40"
                    rows="5"
                    value={contactMsg}
                    onChange={(e) => setContactMsg(e.target.value)}
                    placeholder="こちらにお問い合わせ内容をご入力ください。"
                  ></textarea>
                  <span className="error_blank"></span>
                </dd>
              </dl>
              <div className="seal-flex">
                <div className="seal-block">
                  <p className="heading-basic">
                    当サイトはSSL暗号化通信に対応しております。
                  </p>
                  <p className="text-basic22">
                    第三者による情報の不正取得・改竄・なりすまし等を防止し、安全な通信を確保しております。入力された情報は暗号化して送られますので、安心してご入力ください。
                  </p>
                </div>
                <div className="seal-box">
                  <figure type="image" name="Sticker">
                    <img
                      src="https://www.space-design.co.jp/common/images/seal/jprs_siteseal_140x59_DV.png"
                      alt="SSL暗号化通信に対応しております。"
                    />
                  </figure>
                </div>
              </div>
              <p id="form_submit">
                <input
                  type="button"
                  id="form_submit_button"
                  value="送信する"
                  onClick={handleSubmit}
                />
              </p>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
