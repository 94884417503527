import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/know.css";
import { Link } from "react-router-dom";

function Recruit() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="space_contact_box">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" onClick={toTop}>
              トップ
            </Link>
          </li>
          <li className="breadcrumbs__item">
            学生就職状況・卒業後の進路について
          </li>
        </ul>

        <div className="main_space_div">
          <div className="main_space_div_inner">
            <h1 className="heading-has-under-line">
              学生就職状況・卒業後の進路について
            </h1>
            <h2 className="heading-basic p-recruit-heading">
              2023年3月卒業生　卒業後 3ヶ月以内の就職率
            </h2>
            <figure className="image-basic p-recruit-image">
              <img
                src="	https://www.space-design.co.jp/common/images/pages/recruit/recruit-011_23@2x.png"
                alt="全校 卒業生 卒業後 全コース 就職率"
                width="1200"
                height="600"
              />
            </figure>
            <p className="text-basic-recruit">
              当校ではインテリア・建築関連のデザイン会社への就職率・転職率だけでも90％を越え、非常に高い数値を確保しております。学生の就職活動時期は、作品が出来上がる2月～3月が50％程度で最も多く、早い方では夏頃から始める人が20％程度。作品集作り等をじっくりやられる方は卒業前後の3月～5月で25％強となっております。平均的に活動開始後１カ月程度で内定を頂いています。
            </p>
            <h2 className="heading-has-circle">就職サポート</h2>
            <p className="text-basic-recruit">
              特筆すべきは、卒業生が就職した企業から、「今年もスペースデザインカレッジから人材が欲しい」と言われるところです。これは、当校の学生が現場で必要とされる即戦力を身に付け、インテリア・建築業界で活躍しているからです。地道な企業開発と、授業の中で養われる学生の実践力が、多くの企業に認められているからです。
            </p>
            <ul className="list-basic">
              <li className="list-basic__item">
                授業内での就職活動サポート(特別カリキュラムにて)
              </li>
              <li className="list-basic__item11">スタッフによる就職先の開拓</li>
              <li className="list-basic__item11">求人情報の開示</li>
              <li className="list-basic__item11">就職ガイダンス、個別指導</li>
              <li className="list-basic__item11">
                作品集作りのアドバイス・・・等
              </li>
            </ul>
            <h2 className="heading-has-circle">コース別就職率</h2>
            <div className="image-group">
              <figure className="image-group__item -is-single">
                <img
                  src="https://www.space-design.co.jp/common/images/pages/recruit/recruit-04-0@2x.png"
                  alt="空間デザイン科スペースデザイン専攻(2年制) 照明・エクステリア・その他5% 施工関係13% 販売系デザイン部5% 店舗・オフィス系17% 住宅系60%"
                />
              </figure>
              <figure className="image-group__item">
                <img
                  src="	https://www.space-design.co.jp/common/images/pages/recruit/recruit-02-0@2x.png"
                  alt="空間デザイン科インテリアデザイン専攻(1年制) 照明・エクステリア・その他5% 施工関係10% 販売系デザイン部15% 店舗・オフィス系35% 住宅系35%"
                />
              </figure>
              <figure className="image-group__item">
                <img
                  src="https://www.space-design.co.jp/common/images/pages/recruit/recruit-06-0@2x.png"
                  alt="インテリアコーディネーター科(1年制) 照明・エクステリア・その他5% 施工関係9% 販売系デザイン部35% 店舗・オフィス系16% 住宅系35%"
                />
              </figure>
            </div>
            <h2 className="heading-has-circle">就職先企業</h2>
            <h3 className="heading-has-bg">
              ハウスメーカー・インテリア販売（住宅設備,住宅建材,家具,照明,ファブリック等）
            </h3>
            <p className="text-basic-recruit">
              住友林業・積水ハウス・パナソニック・LIXIL・サンゲツ・TOTO・三井ホーム・三井デザインテック・ミサワホーム・リリカラ・東リ・積水ハイム・シンコール・トクラス・シーアイ化成・永大プランニング・三井不動産販売・東急ホーム・積和建設関西・セキスイツーユーホーム・パナホーム・無印良品・住友不動産・ミノッティ・アルフレックスジャパン・カッシーナイクスシー・アスプルンド・西武不動産・4legs・アクタス・大光電機・ヤマギワ・ＦＤＳ・connect・パル・山装・古河林業・エッセンス・天童木工・藤川工芸・カリモク・IDEE・AD
              CORE・ギャラリー収納・effe・MAKE AND
              SEE・箭木木工・日本ハウス・大塚家具・IKEA・ニトリ・オーダーファニチャーナカジマ・リープテック・コマニー・東急不動産・住まいの設計社・東洋ハウジング・住友不動産シスコン・スカンジナビアデザイン・川島織物・リビタ・デザオ建設・イシモクコーポレーション・アルクインターナショナル・泉北ホーム・Francfranc・インターデコム・タマホーム・シーマテックス・カーテンココ・イオスプラス・KEYUKA・オークヴィレッジ・五洋インテックス・マルニファニシング・マナトレーディング・クラスティーナインターファニチャー・トミタ・サクラファニシング・マルキン・The
              True・・・他多数
            </p>
            <h3 className="heading-has-bg">
              住宅設計・インテリアフォーム・マンション設計
            </h3>
            <p className="text-basic-recruit">
              SUPPOSE DESIGN
              OFFICE・リノベる・ブルースタジオ・スタイル工房・住友建設・大成建設・オープンハウス・松田平田設計・田中雅美建築設計事務所・OKUTA・住友不動産リフォーム・山本理顕設計工場・カミムラ建築設計室・ビルドワークス・タキズミ・グッドリビング・大輪建設・サンリフォーム・IMA
              Design
              Office・住まいの設計社・八清・筒井紀博空間工房・ヤスヒロサワデザインオフィス・城南建設・田端建設・シンプルハウス・春田産業・ハヤト・ベース・河原工房・リニアデザイン・橋爪一級建築士事務所・SCaD・濱田設計測量事務所・プラスチックスタジオ&amp;アソシエイツ・環境計画研究所・グッドリッチ・石井井上建築事務所・ボックスクリエイト・国工務店・アコースティックデザインシステム・菱和ホーム・築設計・ルーヴィス・ミュー・磯田建築設計事務所・カトゥール・立匠・インターナショナルプランニングインスティチュート・ダグ建築設計事務所・河原工房・齊藤建築設計室・サンリフォーム・ブロス住宅・ミスタービルド・スクエア・エスエイチスペース一級建築士事務所・シードスペースデザイン・ICプランニング・スペースラボ・リトルグラウンド・ライフライン・ハウジングスタッフ・グミ・オズ建築事務所・ココロエ一級建築士事務所・シーエムシー一級建築士事務所・やまぜんホームズ・ゆいまーるCLUB・T-plan・インテリックス・・・他多数
            </p>
            <h3 className="heading-has-bg">
              店舗＆ディスプレイデザイン・オフィスデザイン・商業施設設計
            </h3>
            <p className="text-basic-recruit">
              SUPPOSE DESIGN
              OFFICE・丹青社・乃村工藝社・グラマラス・レーベルクリエーターズ・大塚ノリユキデザイン事務所・ステッグコム・西脇一郎デザイン事務所・フォーアイズ・アーキテクトＪ・C.P.O設計・花王・ベイシススペースデザイン・
              design ground 55・アンビエント・40design
              studio・graf・mousetrap・スポイルアソシエイツ・ミュープランニングオペレーターズ・キャラサーチ・エフデザインヴェルト・大山都市建築設計・リチェルカーレ一級建築士事務所・アコースティックデザインシステム・IZANAGI・日浩キングスメン・EMBODY
              DESIGN・ティーエルネットワーク・東京スペース・高杉工房・創楽舎・鈴木デザインワークス・アルゴシーズ・ティディエムワークス・五輪設計・WHATS・テイプランニング・ヤマトマネキン・モーブデザインアソシエイツ・ノミック・ウィズテックデザイン・コンパス建築工房・エクスペリエンスD・ビーズネスト・クロノバデザイン・エレファントライブ・MARU-TAS・FCD・プラスタック・マリアート・ウオッチマン・デコラティブモードナンバースリー・マッチ建築設計事務所・グランクリエイト・solva・プロセス5・インフィクス・池下設計・シエットブランシュ・Ａｆｙｓ佳美成・ステッグコム・トーガシ・ブランコ・彩ユニオン・アンプロジェ・ヴェルト・・・他多数
            </p>
            <p>※順不同</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Recruit;
