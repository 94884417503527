import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/know.css";
import { Link } from "react-router-dom";

function Interview() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="space_contact_box">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" onClick={toTop}>
              トップ
            </Link>
          </li>
          <li className="breadcrumbs__item">
            コンテスト受賞作品・受賞者インタビュー
          </li>
        </ul>

        <div className="main_space_div">
          <div className="main_space_div_inner">
            <h1 className="heading-has-under-line">
              コンテスト受賞作品・
              <br />
              受賞者インタビュー
            </h1>
            <h2 className="heading-has-circle">
              各年度の受賞作品と受賞者へのインタビューをご紹介します。
            </h2>
            <p className="text-basic-recruit">
              当校はインテリア産業協会主催の「住まいのインテリアコーディネーションコンテスト」「キッチン空間アイデアコンテスト」において
              <span className="heading-basic -course-C">
                開校以来27年連続受賞
              </span>
              (2024年現在)しており、日本最多受賞校です。
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Interview;
