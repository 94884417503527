import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/know.css";
import { Link } from "react-router-dom";

function Company() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="space_contact_box">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" onClick={toTop}>
              トップ
            </Link>
          </li>
          <li className="breadcrumbs__item">企業さまインタビュー</li>
        </ul>

        <div className="main_space_div">
          <div className="main_space_div_inner">
            <h1 className="heading-has-under-line">企業さまインタビュー</h1>
            <div class="course-introduction -course-A">
              <h2 class="course-introduction__lede">
                企業の方から見たスペースデザインカレッジと学生に求めるものを伺いました
              </h2>
            </div>
            <h3 class="heading-has-circle -course-A">
              佐藤由理 氏／株式会社デザオ建設 設計部
              <br />
              インテリアコーディネーター責任者
            </h3>
            <p class="course-introduction__text">
              住宅や店舗・ホテルなど様々な分野のコーディネートを内装や照明計画、家具の提案まで手掛ける。
            </p>
            <div class="course-detail">
              <figure class="course-detail__image">
                <img
                  src="https://www.space-design.co.jp/common/images/pages/interview/co-05-satou.jpg"
                  alt="佐藤由理 氏"
                />
              </figure>
              <div class="course-detail__text-group">
                <p class="text-basic">
                  <span class="heading-basic -course-A">
                    スペースデザインカレッジに通う学生はどんな印象ですか？
                  </span>
                </p>
                <p class="text-basic">
                  「最前線で活躍するため」という目標を明確にもった人が、知識・技術を身につけるために通学されているという印象です。
                </p>
              </div>
            </div>

            <div class="course-detail__text-group">
              <p class="text-basic">
                <span class="heading-basic -course-A">
                  どんな学生を採用したいですか？
                </span>
                <br />
                <br />
                自分の考えをしっかり持って、実行しようと努力できる人。
              </p>
            </div>

            <div class="course-detail__text-group">
              <p class="text-basic">
                <span class="heading-basic -course-A">
                  未経験でこれから学ぼうとしている人へ一言お願いします。
                </span>
                　<br />
                <br />
                旅行に行って素敵な宿に泊まったり、行きつけのカフェでお茶をしたり、いつも帰りたくなるお家があったり、これらをいかに居心地よい空間にするかは設計士やインテリアコーディネーターの仕事です。心が安らぐその空間を自分が作れると考えるとこんな素敵なことは他にないと思います。日々勉強すること、新しい知識を得ることを楽しみながら頑張ってほしいです。
              </p>
            </div>

            <h3 class="heading-has-circle -course-A">
              山田英孝 氏／株式会社リヴ 人事 採用担当責任者
            </h3>
            <p class="course-introduction__text">
              2010年総合人材サービス株式会社パソナグループ入社。2015年株式会社リヴに入社後、新卒、中途採用、パート、アルバイトなどの採用を統括している。人事経験は約10年となり、今までのべ1,000名の面談を行う。人事経験を通じて多様な人の価値観、多様なキャリアに触れてきた。
            </p>
            <div class="course-detail">
              <figure class="course-detail__image">
                <img
                  src="	https://www.space-design.co.jp/common/images/pages/interview/co-04-yamada.jpg"
                  alt="山田英孝 氏"
                />
              </figure>
              <div class="course-detail__text-group">
                <p class="text-basic">
                  <span class="heading-basic -course-A">
                    スペースデザインカレッジに通う学生はどんな印象ですか？
                  </span>
                </p>
                <p class="text-basic">
                  卒業生は数名働いてくれており、皆活躍していただいています。社会人経験があるので、ぶれない芯を持っています。向上心もあり自らチャレンジする大変魅力ある人たちです。
                </p>
              </div>
            </div>
            <div class="course-detail__text-group">
              <p class="text-basic">
                <span class="heading-basic -course-A">
                  どんな学生を採用したいですか？
                </span>
                <br />
                <br />
                お客様、地域、弊社が皆、良くなる「三方よし」の考え方に共感をしてくれることと、そして何よりも人柄です。「お客様に〇〇さんに出会えてよかった！」と思っていただける良い影響を与えてほしいです。素直で夢に向かう情熱とエネルギーを高く持っている人と一緒に働きたいと思っています。
              </p>
            </div>
            <div class="course-detail__text-group">
              <p class="text-basic">
                <span class="heading-basic -course-A">
                  未経験でこれから学ぼうとしている人へ一言お願いします。
                </span>
                　<br />
                <br />
                今、別の業界で働いていたとしてもその経験は必ず仕事に活かせます。意欲があれば、年齢に関係なく夢を掴むことができるのでぜひ挑戦してください。
              </p>
            </div>
            <h3 class="heading-has-circle -course-A">
              {" "}
              原田潤一 氏／株式会社湖都コーポレーション代表
            </h3>
            <p class="course-introduction__text">
              2005年に株式会社湖都コーポレーション設立。野洲本社、アウトドア感あふれる彦根にショールームを展開。趣味は旅行。愛犬と戯れること。
            </p>
            <div class="course-detail">
              <figure class="course-detail__image">
                <img
                  src="	https://www.space-design.co.jp/common/images/pages/interview/co-02-harada.jpg"
                  alt="原田潤一 氏"
                />
              </figure>
              <div class="course-detail__text-group">
                <p class="text-basic">
                  <span class="heading-basic -course-A">
                    スペースデザインカレッジの学生を見て感じることは何ですか？
                  </span>
                </p>
                <p class="text-basic">
                  真面目です！　現在、スペースデザインカレッジの卒業生が複数名働いてくれています。社会に出て即戦力となる勉強をしてきた彼らは、それぞれの役割をしっかり担ってくれています。また、一度社会人を経験しているため、自分で考える力を備えているのも特徴。取り組む姿勢も熱く、会社の方針についてきてくれることが経営者としてはうれしい限りです。
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div class="course-detail__text-group">
              <p class="text-basic">
                <span class="heading-basic -course-A">
                  どんな学生を雇用したいですか？
                </span>
                <br />
                <br />
                勉強ができる、成績が優秀、ということよりも、状況をよく理解し、どうすればいいかを考えられる人がいいですね。加えて、コミュニケーション能力も重要です。採用前にいろいろと書類や作品を見たり、面接をしたりしますが、正直さや素直さもポイントとみなしています。
              </p>
            </div>
            <h3 class="heading-has-circle -course-A">
              {" "}
              岩本勝也 氏／EMBODY DESIGN 代表
            </h3>
            <p class="course-introduction__text">
              大阪芸術大学でインテリアデザインを学び、卒業後に丹青社入社。1992年にエンバディデザイン設立。2004年にレーベルクリエーターズ設立。受賞歴、著書も多数。趣味はサーフィン。
            </p>
            <div class="course-detail">
              <figure class="course-detail__image">
                <img
                  src="https://www.space-design.co.jp/common/images/pages/interview/co-01-iwamoto.jpg"
                  alt="岩本勝也 氏"
                />
              </figure>
              <div class="course-detail__text-group">
                <p class="text-basic">
                  <span class="heading-basic -course-A">
                    スペースデザインカレッジの学生を見て感じることは何ですか？
                  </span>
                </p>
                <p class="text-basic">
                  入学資格が20歳以上であるスペースデザインカレッジに通う学生は、未成年の判断とは異なり、意気込みが見えます。これまでの経験を別な仕事に変換して考える力がこの業界では必要です。教鞭をとったのは10年以上前ですが、学生の中にはプラスに変換できる人が多かったですね。そういった意味では大学3年生、4年生に等しいと思います。
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div class="course-detail__text-group">
              <p class="text-basic">
                <span class="heading-basic -course-A">
                  どんな学生を雇用したいですか？
                </span>
                <br />
                <br />
                授業にしっかり出るのはもちろんですが（笑）、柔軟な発想ができることでしょうか。移り変わりの早い社会で生き残り方を考えられる前向きな人がいいですね。将来は独立をしたい！
                そのぐらいの気構えがある人も実際にここで働いています。そんな彼らとEMBODY
                DESIGNでパートナーシップを構築していくのも、今後の責務と思っています。
              </p>
            </div>
            <h3 class="heading-has-circle -course-A">
              {" "}
              坂元太一 氏／株式会社ティプランニング代表取締役
            </h3>
            <p class="course-introduction__text">
              2008年に株式会社ティプランニングを設立。
            </p>
            <div class="course-detail">
              <figure class="course-detail__image">
                <img
                  src="https://www.space-design.co.jp/common/images/pages/interview/co-03-sakamoto.jpg"
                  alt="坂元太一 氏"
                />
              </figure>
              <div class="course-detail__text-group">
                <p class="text-basic">
                  <span class="heading-basic -course-A">
                    スペースデザインカレッジに通う学生はどんな印象ですか？
                  </span>
                </p>
                <p class="text-basic">
                  スタート地点でのスキル、知識量の多さや社会人としての基礎が身についているという印象です。
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div class="course-detail__text-group">
              <p class="text-basic">
                <span class="heading-basic -course-A">
                  この業界で力を発揮するにはどのような能力や気持ちが必要ですか？
                </span>
                <br />
                <br />
                社会人として活躍するにはデザインを具現化していく上で多くの人が関わります。デザイナーとしての知識、スキルだけでなく思いやビジョンと共にそれを実際に形にする能力も必要です。どんな仕事も１人ではできないので人の思いを理解する、また自分の思いを伝える、コミュニケーション能力が大切です。
              </p>

              <div class="course-detail__text-group">
                <p class="text-basic">
                  <span class="heading-basic -course-A">
                    これから学ぼうとしている人へ一言お願いします。
                  </span>
                  <br />
                  <br />
                  才能がないと不安を感じている人もいると思いますが、デザインを学びたい、また夢を実現したいという気持ちがすでに才能があるということです。独自の創造力を持ち、他人の価値を尊重できる人材が多くを学び、多くの人と出会い、たくさんの試行錯誤を繰り返すことで夢の実現に近づくと思います。必ず活躍する人材になると信じてます。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Company;
