import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Privacy() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="space_contact_box">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" onClick={toTop}>
              トップ
            </Link>
          </li>
          <li className="breadcrumbs__item">プライバシーポリシー</li>
        </ul>

        <div className="main_space_div">
          <div className="main_space_div_inner">
            <h1 className="heading-has-under-line">プライバシーポリシー</h1>
            <h2 className="heading-has-circle">
              スペースデザインカレッジは個人情報保護に関するコンプライアンス意識を持ち、以下の方針に基づいて管理・運営しております。
            </h2>
            <p className="text-basic-privacy">
              １
              個人情報の定義本ポリシーでは以下のものを「個人情報」と定義します。
              <br />
              個人に関する情報とは当該情報に含まれる氏名、生年月日、住所、電話番号、メールアドレス、学歴、その他の記述等、個人別に付された番号、記号その他の符号により、当該個人と識別できるものをいいます。
              <br />
              また当該情報のみでは識別できない場合でも、他の情報と容易に照合することで当該個人と識別できるものを含みます。
              <br />
              <br />
              ２
              個人情報をお伺いする場合スペースデザインカレッジでは、当校の教育内容への理解を深めていただくため、次のような場合に皆様の個人情報をお伺いする場合がございます。
              <br />
              ・ スペースデザインカレッジ資料請求 <br />
              ・ 学校説明会のお問い合わせ、参加申し込み <br />
              ・ セミナー、体験授業のお問い合わせ、参加申し込み <br />
              ・ 合評会見学のお問い合わせ、見学申し込み <br />
              ・ 作品展のお問い合わせ、見学申し込み <br />
              ・ 書籍のお問い合わせ、購入申し込み <br />
              ・ 電子メール配信への登録 <br />
              ・ その他　スペースデザインカレッジへのお問い合わせ
              <br />
              <br />
              ３
              個人情報の保護・取り扱いについて　スペースデザインカレッジでは情報機器、電子媒体、紙媒体、各種データ、など形態の如何を問わず取扱う情報資産において、適切な保護、取扱いの実践ができるよう、情報セキュリティポリシーに基づく運営を行っております。
              <br />
              個人情報に対して、漏洩、不正使用、改ざん等の事態が発生しないよう、最大限の注意を払っております。
              <br />
              <br />
              ４
              スペースデザインカレッジは個人情報を皆様のご同意がない限り、学校教職員以外の第三者に開示することは一切いたしません。
              <br />
              ただし、下記の項目に該当する場合には、皆様の個人情報を開示する場合がございますので、予めご了承くださいますようお願いいたします。
              <br />
              ・
              法令に基づく場合・人の生命、身体又は財産の保護のために必要である場合であって、本人の同意を得ることが困難であるとき。
              <br />・
              公衆衛生の向上のために特に必要がある場合であって、本人の同意を得ることが困難であるとき。{" "}
              <br />
              ・
              国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行に支障を及ぼすおそれがあるとき。
              <br />
              <br />
              ５
              情報配信について、皆様が個人情報を提供され、かつご了承をいただいた場合、スペースデザインカレッジから電子メール、郵送などの方法により、皆様に有益と思われる情報を配信することがございます。
              <br />
              なお皆様が希望されない場合には、スペースデザインカレッジまでご連絡をいただければ情報の配信を停止いたします。
              <br />
              <br />
              ６
              皆様の個人情報に誤り、変更などがあった場合、または皆様が個人情報の削除を希望される場合、スペースデザインカレッジまでご連絡をいただければ、すみやかに訂正、削除を行います。
              <br />
              <br />
              ７
              スペースデザインカレッジは、皆様に信頼と安心をしていただくために、本プライバシーポリシーと情報セキュリティポリシーを適宜見直し、改訂してまいります。
              <br />
              なお、改訂されたプライバシーポリシーと情報セキュリティポリシーはすみやかに公開いたします。
            </p>
            <h3 className="heading-has-bg">お問い合わせ先</h3>
            <h4 className="heading-basic">東京校</h4>
            <p className="text-basic-privacy">
              TEL：03-5919-2700
              <br />
              E-Mail：
              <a href="mailto:work@huiixu.click">work@huiixu.click</a>
              <br />
              〒160-0022　東京都新宿区新宿2-9-22 多摩川新宿ビル8階
            </p>
            <h4 className="heading-basic">横浜校</h4>
            <p className="text-basic-privacy">
              TEL：045-263-9318
              <br />
              E-Mail：
              <a href="mailto:work@huiixu.click">work@huiixu.click</a>
              <br />
              〒231-0002　神奈川県横浜市中区海岸通4-23 マリンビル204
            </p>
            <h4 className="heading-basic">京都校</h4>
            <p className="text-basic-privacy">
              TEL：075-253-1561
              <br />
              E-Mail：
              <a href="mailto:work@huiixu.click">work@huiixu.click</a>
              <br />
              〒604-8152　京都市中京区烏丸通蛸薬師下ル手洗水町650　四条烏丸スタービル8F
            </p>
            <h4 className="heading-basic">大阪校</h4>
            <p className="text-basic-privacy">
              {" "}
              TEL：06-6365-0017
              <br />
              E-Mail：
              <a href="mailto:work@huiixu.click">work@huiixu.click</a>
              <br />
              〒530-0047　大阪市北区西天満2-8-1 大江ビルヂング202・203・218{" "}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
