import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/know.css";
import { Link } from "react-router-dom";

function Ob() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="space_contact_box">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" onClick={toTop}>
              トップ
            </Link>
          </li>
          <li className="breadcrumbs__item">卒業生インタビュー</li>
        </ul>

        <div className="main_space_div">
          <div className="main_space_div_inner">
            <h1 className="heading-has-under-line">卒業生インタビュー</h1>
            <div className="course-introduction -course-C">
              <h2 className="course-introduction__lede">
                インテリア・建築業界で夢を叶えた 卒業生をご紹介します
              </h2>
            </div>
            <h3 className="heading-has-circle -course-C">
              インテリアデザイン設計科卒／佐藤麻紀さん／インテリアデザイン事務所「スコップ」主宰
            </h3>
            <div className="course-detail">
              <figure className="course-detail__image">
                <img
                  src="https://www.space-design.co.jp/common/images/pages/interview/ob-01-sato.jpg"
                  alt="佐藤麻紀さん"
                />
              </figure>
              <div className="course-detail__text-group">
                <p className="text-basic00">
                  <span className="redtext">
                    ※インテリアデザイン設計科...現・空間デザイン科インテリアデザイン専攻
                  </span>
                  <br />
                  <br />
                  スペースデザインカレッジ入学前は旅行会社の営業を４年。
                  転職を考え好きな道に進もうと一歩踏み出した佐藤さん。
                  <br />
                  卒業後に入った会社で力を付け、独立を進められ入社３年後に独立を果たした。
                </p>
              </div>
            </div>

            <div className="course-detail__text-group">
              <p className="text-basic00">
                --------------
                <br />
                住宅、店舗のインテリアデザインと施工管理を行っています。リノベーション（リフォーム）中心です。
                <br />
                限られた予算の中で良いデザインをしていくことがとても難しく感じています。けれども制約がある中から工夫が生まれ、かえって良いデザインが出来たりします。
                <br />
                自分の案が通り、お客様が喜んでくれたときは本当に嬉しいです。
                <br />
                自分が携わったいくつかの物件が雑誌に掲載されたこともあります。
                <br />
                安い予算でも良い空間が出来るということで、雑誌の反響から新たな仕事をいくつか受注することも出来ました。
                <br />
                忙しい時は４つの物件を同時に抱えることもあり、大変ですがやり甲斐も大きいです。
                <br />
                リノベーションの現場では壊してみて初めてわかる事もあり、予測していなかった様々な問題が発生しまが、それをクリアすることで自分が成長していることを実感できます。失敗もありますけど・・・。毎日が勉強です。
                <br />
                <br />
              </p>
            </div>
            <h3 className="heading-has-circle -course-C">
              インテリアコーディネーター科卒／大矢沙代さん／工務店勤務
            </h3>
            <div className="course-detail">
              <figure className="course-detail__image">
                <img
                  src="https://www.space-design.co.jp/common/images/pages/interview/ob-02-oya.jpg"
                  alt="大矢沙代さん"
                />
              </figure>
              <div className="course-detail__text-group">
                <p className="text-basic00">
                  インテリアのコーディネート業務をしています。
                  <br />
                  吉野杉・珪藻土などの自然素材、大工さんの手刻み、完全注文建築が特徴の工務店です。
                  <br />
                  奈良・大阪2拠点、社員40人、年間60棟、コーディネーター5人という規模で、担当目標年間12棟。売上目標金額も設定あります。
                  <br />
                  外装、設備、配線、造作、内装、照明、カーテン、外構の打合せ。調べもの、見積、図面、プラン、発注、現場確認、ショールーム確認、雑務、等々。
                  <br />
                  不定期で展示場の家具小物入れ替えや営業イベント(セミナー、見学会)補助の仕事もあります。
                  <br />
                  ハウスメーカーのICに比べて仕事の範囲が広いと思います。その分、やりがいはありますね。
                </p>
              </div>
            </div>

            <h3 className="heading-has-circle -course-C">
              スペースデザイン設計科卒／藤井真二さん／デザイン事務所勤務
            </h3>
            <div className="course-detail">
              <figure className="course-detail__image">
                <img
                  src="https://www.space-design.co.jp/common/images/pages/interview/ob-03-fujii.jpg"
                  alt="藤井真二さん"
                />
              </figure>
              <div className="course-detail__text-group">
                <p className="text-basic00">
                  <span className="redtext">
                    ※スペースデザイン設計科...現・空間デザイン科スペースデザイン専攻
                  </span>
                  <br />
                  <br />
                  大阪、東京、上海、北京、韓国における商空間のプロデュース・設計、またビル、住宅等建築の設計を行っており、デザイナーとして物件を持ちプランニングをしています。
                  <br />
                  <br />
                  学生時代に身につけたプレゼン力は、ライバルの多いこの業界で仕事をつかむのに役に立っています。
                  「デザインしたプランをどのように、施主の心に響かせるのか」が重要で、クラス内発表や合評会では、紙面構成、話術、身だしなみなど、仕事の基本になることをしっかりと身につけることができました。
                </p>
              </div>
            </div>
            <h3 className="heading-has-circle -course-C">
              インテリアデザイン設計科卒／飯泉洋紀さん／設計事務所勤務
            </h3>
            <div className="course-detail">
              <figure className="course-detail__image">
                <img
                  src="	https://www.space-design.co.jp/common/images/pages/interview/ob-04-iizumi.jpg"
                  alt="飯泉洋紀さん"
                />
              </figure>
              <div className="course-detail__text-group">
                <span className="redtext">
                  ※インテリアデザイン設計科...現・空間デザイン科インテリアデザイン専攻
                </span>
                <br />
                <br />
                <p className="text-basic00">
                  在学中、設計事務所のオープンデスクに通っていた飯泉さん。
                  <br />
                  自然と住宅設計の魅力を知り、 実力が認められ入社の運びとなる。
                </p>
                <p className="text-basic00">
                  --------------
                  <br />
                  個人邸の設計、監理を行っています。
                  <br />
                  プレゼンから引き渡しまで一貫した業務です。都内での依頼が多いため、狭小住宅の案件が多いです。
                  <br />
                  入社して3棟目に担当した「K-nest」という住宅が印象に残っていますね。
                  少しずつ設計の仕事に慣れてきた頃で、構造や法規を乗り越える難しさに直面し、一つずつ解決していく面白さを知った物件でした。
                  <br />
                  外から見える建築の形が中にも生きてくることで、空間がより豊かになるのだと学び、住宅の設計をやりたいと再確認できた仕事でした。
                  <br />
                  もっと色々な設計ができるようになりたいですね。会社も仕事が増えてきて新しいことにも挑戦しているので、より会社が大きくなるように協力したいです。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Ob;
