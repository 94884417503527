import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottomAll.css";
import { Link } from "react-router-dom";

function Collect() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="space_contact_box">
        <ul className="breadcrumbs">
          <li className="breadcrumbs__item">
            <Link to="/" onClick={toTop}>
              トップ
            </Link>
          </li>
          <li className="breadcrumbs__item">募集要項</li>
        </ul>

        <div className="main_space_div">
          <div className="main_space_div_inner">
            <h1 className="heading-has-under-line">募集要項</h1>
            <h2 className="heading-has-circle -course-J">募集要項</h2>
            <h3 className="heading-has-bg -course-J">応募資格</h3>
            <ul className="list-basic -has-disc -course-J">
              <li className="list-basic__item">
                20歳以上の方(入学年度中に20歳を迎えられる方は対象)
              </li>
              <li className="list-basic__item">高等学校卒業者</li>
              <li className="list-basic__item">
                インテリア・建築、その他各分野に興味があり、しっかり学ぶ意欲のある方
              </li>
            </ul>
            <h3 className="heading-has-bg -course-J">選考方法</h3>
            <p className="heading-basic -course-J">面接にて審査</p>
            <h3 className="heading-has-bg -course-J">
              コース別の授業料
              <br />
            </h3>
            <h4 className="heading-basic -course-J">空間デザイン科</h4>
            <table className="table-basic">
              <tbody>
                <tr>
                  <th className="table-basic__header">受講期間</th>
                  <td className="table-basic__data">1年間</td>
                </tr>
                <tr>
                  <th className="table-basic__header">費用</th>
                  <td className="table-basic__data">
                    <p>1,100,000円(税込)</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <h4 className="heading-basic -course-J">
              インテリアコーディネーター科
            </h4>
            <table className="table-basic">
              <tbody>
                <tr>
                  <th className="table-basic__header">受講期間</th>
                  <td className="table-basic__data">1年間</td>
                </tr>
                <tr>
                  <th className="table-basic__header">費用</th>
                  <td className="table-basic__data">499,400円(税込)</td>
                </tr>
              </tbody>
            </table>
            <h4 className="heading-basic -course-J">
              インテリアコーディネーター科 オンラインコース
            </h4>
            <table className="table-basic">
              <tbody>
                <tr>
                  <th className="table-basic__header">受講期間</th>
                  <td className="table-basic__data">1年間</td>
                </tr>
                <tr>
                  <th className="table-basic__header">費用</th>
                  <td className="table-basic__data">217,800円(税込)</td>
                </tr>
              </tbody>
            </table>
            <h3 className="heading-has-bg -course-J">入学金・選考料等</h3>
            <h4 className="heading-basic -course-J">空間デザイン科</h4>
            <table className="table-basic">
              <tbody>
                <tr>
                  <th className="table-basic__header">出願料・選考料</th>
                  <td className="table-basic__data"> 0円</td>
                </tr>
                <tr>
                  <th className="table-basic__header">入学金</th>
                  <td className="table-basic__data"> 110,000円(税込)</td>
                </tr>
              </tbody>
            </table>
            <h4 className="heading-basic -course-J">
              インテリアコーディネーター科
            </h4>
            <table className="table-basic">
              <tbody>
                <tr>
                  <th className="table-basic__header">出願料・選考料</th>
                  <td className="table-basic__data"> 0円</td>
                </tr>
                <tr>
                  <th className="table-basic__header">入学金</th>
                  <td className="table-basic__data">33,000円(税込)</td>
                </tr>
              </tbody>
            </table>
            <h4 className="heading-basic -course-J">
              インテリアコーディネーター科 オンラインコース
            </h4>
            <table className="table-basic">
              <tbody>
                <tr>
                  <th className="table-basic__header">出願料・選考料</th>
                  <td className="table-basic__data"> 0円</td>
                </tr>
                <tr>
                  <th className="table-basic__header">入学金</th>
                  <td className="table-basic__data">33,000円(税込)</td>
                </tr>
              </tbody>
            </table>

            <h2 className="heading-has-circle -course-J">
              転籍・編入制度について
            </h2>
            <h3 className="heading-has-bg -course-J">転籍制度について</h3>
            <p className="text-basic">
              各校空間デザイン科インテリアデザイン専攻・インテリアコーディネーター科を卒業された方は
              東京校・
              京都校・大阪校の空間デザイン科スペースデザイン専攻2年次に転籍が可能です。(面接選考有り)
            </p>
            <h3 className="heading-has-bg -course-J">編入制度について</h3>
            <p className="text-basic">
              空間デザイン科インテリアデザイン専攻卒業者、又は、実務経験、他校での修学経験等により当校学生と同等の知識・技術力と認められる場合に、空間デザイン科スペースデザイン専攻2年次への入学資格(編入入学)が得られます。※学費合計は空間デザイン科インテリアデザイン専攻と同額です。
            </p>
            <h3 className="heading-has-bg -course-J">
              学費のお支払い方法・教育ローン制度について
            </h3>
            <p className="text-basic">
              一括でのお支払いの他、教育ローンも可能です
            </p>
            <h3 className="heading-has-bg -course-J">教育ローン制度</h3>
            <p className="text-basic">
              教育ローンは入学前に融資が受けられます。卒業後の返済スタートが可能なため在学中は勉強に集中する環境をつくる事ができます。教育ローンの使い道は入学金や授業料だけでなく入学に伴う引越費用などにもご利用いただけます。
            </p>
            <h4 className="heading-basic -course-J">
              「国の教育ローン」返済シミュレーション
            </h4>
            <p className="text-basic">
              以下は「国の教育ローン」における返済プラン例です。
            </p>
            <p className="text-basic">※金利は2024年10月時点</p>

            <table className="table-basic">
              <tbody style={{ textAlign: "center" }}>
                <tr style={{ border: "solid 1px #333" }}>
                  <th
                    className="table-basic__header"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#787878",
                      color: "#fff",
                      borderColor: "#333",
                    }}
                  >
                    条件
                    <br className="sp-br" />
                    （金利2.40%、元金据置なし）
                  </th>
                  <th
                    className="table-basic__header"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#787878",
                      color: "#fff",
                      borderColor: "#333",
                    }}
                  >
                    毎月
                  </th>
                  <th
                    className="table-basic__header"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#787878",
                      color: "#fff",
                      borderColor: "#333",
                    }}
                  >
                    返済総額
                    <br className="sp-br" />
                    （内、利息金額）
                  </th>
                </tr>
                <tr>
                  <th
                    className="table-basic__header"
                    style={{ textAlign: "center" }}
                  >
                    50万円 / <br className="sp-br" />
                    5年返済（59回払い）
                  </th>
                  <td className="table-basic__data">¥9,000</td>
                  <td className="table-basic__data">
                    ¥530,600
                    <br className="sp-br" />
                    （¥30,600）
                  </td>
                </tr>
                <tr>
                  <th
                    className="table-basic__header"
                    style={{ textAlign: "center" }}
                  >
                    50万円 / <br className="sp-br" />
                    10年返済（119回払い）
                  </th>
                  <td className="table-basic__data">¥4,800</td>
                  <td className="table-basic__data">
                    ¥562,300
                    <br className="sp-br" />
                    （¥62,300）
                  </td>
                </tr>
                <tr>
                  <th
                    className="table-basic__header"
                    style={{ textAlign: "center" }}
                  >
                    100万円 / <br className="sp-br" />
                    5年返済（59回払い）
                  </th>
                  <td className="table-basic__data">¥18,000</td>
                  <td className="table-basic__data">
                    ¥1,061,200
                    <br className="sp-br" />
                    （¥61,200）
                  </td>
                </tr>
                <tr>
                  <th
                    className="table-basic__header"
                    style={{ textAlign: "center" }}
                  >
                    100万円 / <br className="sp-br" />
                    10年返済（119回払い）
                  </th>
                  <td className="table-basic__data">¥9,500</td>
                  <td className="table-basic__data">
                    ¥1,124,700
                    <br className="sp-br" />
                    （¥124,700）
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="text-basic">
              「国の教育ローン」に関する詳細はこちらをご参照ください。任意の条件で返済シミュレーションが可能です。
            </p>
            <div className="button-arrow-group -course-J">
              <Link
                to="https://www.jfc.go.jp/n/finance/search/ippan.html"
                target="_blank"
                className="button-arrow -is-full"
                style={{ marginTop: "20px" }}
              >
                国の教育ローン(日本政策金融公庫 公式HP)
              </Link>
            </div>
            <p className="text-basic">
              「ジャックスの教育ローン」はこちらをご参照ください。
            </p>
            <div className="button-arrow-group -course-J">
              <Link
                to="https://www.jaccs.co.jp/service/credit/education/yuyu/"
                target="_blank"
                className="button-arrow -is-full"
                style={{ marginTop: "20px" }}
              >
                ジャックスの教育ローン(ジャックス公式HP)
              </Link>
            </div>
            <h3 className="heading-has-bg -course-J">分割払い制度</h3>
            <h4 className="heading-basic -course-J">分割払いのご利用条件</h4>
            <p className="text-basic">
              ・当校の説明会に参加していること
              <br />
              ・入学学科を卒業するまでに学費を納めること
              <br />
              各お支払い方法について、詳しくは学校説明会にてご説明しております。
              ご検討中の方はお早めに学校説明会にご参加ください。
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Collect;
